<template>
  <div class="home">
    <section class="home--section-left">
      <a :href="bannerUrl" target="_blank" class="home__link">
        <v-img :src="require(`@/assets/images/petzl.jpg`)" position="top center" max-width="100%" height="100vh">
        </v-img>
      </a>
    </section>
    <section class="home--section-right pa-4 pa-sm-6 pa-lg-8">
      <div class="home__container">
        <header class="home__container__header">
          <div class="home__container__header__left">
            <a href="https://www.petzl.com/" target="_blank">
              <img :src="require('@/assets/images/logo-petzl.png')" alt="Petzl" />
            </a>
          </div>
        </header>
        <div class="home__container__main">
          <div class="home__container__main__intro text-center mt-12">
            {{ $t('home.intro') }}
          </div>
          <h1 class="home__container__main__title text-center mt-4" v-html="$t('home.title')"></h1>
          <!-- <div class="home__container__main__separator">
            <img class="home__container__main__gift mt-4" :src="require('@/assets/images/cadeau.png')" />
          </div> -->
          <div class="home__container__main__caption text-center mt-1" v-html="$t('home.caption')"></div>
          <v-btn
            depressed
            color="#f38f0f"
            tile
            x-large
            height="54"
            :href="linkHref"
            class="home__container__main__link text-h6 mt-8"
          >
            {{ $t('home.cta') }}
          </v-btn>
        </div>
        <div class="home__container__language mt-6">
          <div class="home__container__language__label mb-2">{{ $t('home.language.label') }}</div>

          <v-select
            class="home__container__language__label__selector"
            :items="items"
            v-model="currentLanguage"
            outlined
            hide-details
            solo
            @change="onChange"
          >
            <template v-slot:selection="{ item }">
              <span class="d-flex justify-center text-uppercase" style="width: 100%">
                {{ item.text }}
              </span>
            </template>
          </v-select>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: vm => ({
    currentLanguage: vm.$i18n.locale,
    items: [
      {
        text: 'English - UK',
        value: 'en',
        href: `https://petzl-sport-uk.gtr.cx`,
        website: 'https://www.petzl.com/GB/en/',
      },
      {
        text: 'English - US',
        value: 'us',
        href: `https://petzl-sport-us.gtr.cx`,
        website: 'https://www.petzl.com/US/en/',
      },
      {
        text: 'Français',
        value: 'fr',
        href: `https://petzl-sport-fr.gtr.cx`,
        website: 'https://www.petzl.com/FR/fr',
      },
      {
        text: 'Deutsch',
        value: 'de',
        href: `https://petzl-sport-de.gtr.cx`,
        website: 'https://www.petzl.com/DE/de',
      },
      {
        text: 'Español',
        value: 'es',
        href: `https://petzl-sport-es.gtr.cx`,
        website: 'https://www.petzl.com/ES/es',
      },
      {
        text: 'Italiano',
        value: 'it',
        href: `https://petzl-sport-it.gtr.cx`,
        website: 'https://www.petzl.com/IT/it',
      },
      {
        text: 'Nederlands',
        value: 'du',
        href: `https://petzl-sport-du.gtr.cx`,
        website: 'https://www.petzl.com/NL/en/',
      },
      {
        text: 'Svenska',
        value: 'se',
        href: `https://petzl-sport-sw.gtr.cx`,
        website: 'https://www.petzl.com/SE/en/',
      },
      {
        text: 'Norsk',
        value: 'no',
        href: `https://petzl-sport-no.gtr.cx`,
        website: 'https://www.petzl.com/NO/en/',
      },
      {
        text: 'Fin',
        value: 'fi',
        href: `https://petzl-sport-fi.gtr.cx`,
        website: 'https://www.petzl.com/FI/en/',
      },
    ],
  }),
  metaInfo: vm => ({
    title: vm.$t('meta.title'),
    meta: [{ name: 'description', content: vm.$t('meta.description') }],
  }),
  computed: {
    itemByLocale() {
      return this.items.find(item => item.value === this.currentLanguage)
    },
    linkHref() {
      const item = this.itemByLocale
      return `${item.href}`
    },
    bannerUrl() {
      const item = this.itemByLocale
      return item.website
    },
  },
  methods: {
    onChange(locale) {
      this.$i18n.locale = locale
    },
    logoByLocale(param) {
      const item = this.itemByLocale
      return item.logo ? item.logo[param] : null
    },
  },
}
</script>

<style lang="scss">
.home {
  display: flex;
  min-height: 100%;

  &--section-left {
    display: none;
    position: fixed;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      display: flex;
      width: calc(100% - 600px);
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      width: calc(100% - 900px);
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      width: calc(100% - 1100px);
    }

    @media (min-width: map-get($grid-breakpoints, 'xxl')) {
      width: calc(100% - 1300px);
    }
  }

  &__link {
    max-width: 100%;
  }

  &--section-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-bg;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      margin-left: calc(100% - 600px);
      width: 600px;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      margin-left: calc(100% - 900px);
      width: 900px;
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      margin-left: calc(100% - 1100px);
      width: 1100px;
    }

    @media (min-width: map-get($grid-breakpoints, 'xxl')) {
      margin-left: calc(100% - 1300px);
      width: 1300px;
    }
  }

  &__logo {
    position: absolute;
    bottom: $gutter-tablet;
    left: $gutter-tablet;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      bottom: $gutter-desktop;
      left: $gutter-desktop;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    justify-content: space-between;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      max-width: 550px;
    }

    &__header {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;

      &__left,
      &__center,
      &__right {
        display: flex;
        position: relative;
        justify-content: center;

        a {
          display: inline-flex;

          img {
            max-width: 100%;
            height: 100px;
          }
        }
      }

      &__center {
        &:before {
          position: absolute;
          top: 50%;
          left: -16px;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.8);
          width: 2px;
          height: 70%;
          content: '';
        }
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: uppercase;

      &__gift {
        height: 150px;
        position: relative;
        background: rgb(255, 255, 255);
      }

      &__intro {
        text-transform: initial;
        font-size: 1.375rem;
      }

      &__title {
        color: $color-text-accent;
        font-size: 1.875rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2.5rem;
        }
      }

      &__separator {
        position: relative;
        width: 100%;
        text-align: center;
        color: $color-text-main;
        font-size: 1.5rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }

        &:before {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.7);
          height: 2px;
          content: '';
        }

        &__label {
          position: relative;
          background-color: $color-bg;
        }
      }

      &__subtitle {
        text-transform: uppercase;
        color: $color-text-accent;
        font-size: 1.7rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2.25rem;
        }
      }

      &__caption {
        color: $color-text-main;
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }
      }

      &__prices {
        text-transform: initial;
        color: $color-text-alt;

        a {
          color: $color-text-alt !important;
        }
      }

      &__link {
        font-weight: bold;
      }
    }

    &__language {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__label {
        text-transform: uppercase;
        color: $color-text-main;
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
